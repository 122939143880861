import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

const getMarkerClass = marker => {
  switch (marker) {
    case "small":
      return <div className="timeline-marker " />;
    case "big":
      return <div className="timeline-marker is-icon" />;
    case "flag":
      return (
        <div className="timeline-marker is-danger is-icon">
          <i className="fa fa-flag" />
        </div>
      );
    default:
      return <div className="timeline-marker " />;
  }
};

export const TimelinePageTemplate = ({ title, timelines }) => {
  useEffect(() => {
    window.addEventListener("resize", () => {
      const width = document.documentElement.clientWidth;
      const tl = document.getElementsByClassName("timeline")[0];
      if (tl) {
        if (width >= 600) {
          tl.classList.add("is-centered");
        } else {
          tl.classList.remove("is-centered");
        }
      }
    });
    window.dispatchEvent(new Event("resize"));
  }, []);

  const toggleSeeMore = (periodIndex, milestoneIndex) => () => {
    if (
      document.getElementById(`moreText${periodIndex}-${milestoneIndex}`).style
        .display === "none"
    ) {
      document.getElementById(
        `moreText${periodIndex}-${milestoneIndex}`,
      ).style.display = "block";
      document.getElementById(
        `seeMore${periodIndex}-${milestoneIndex}`,
      ).innerHTML = "See less";
    } else {
      document.getElementById(
        `moreText${periodIndex}-${milestoneIndex}`,
      ).style.display = "none";
      document.getElementById(
        `seeMore${periodIndex}-${milestoneIndex}`,
      ).innerHTML = "See more";
    }
  };

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>

              <div className="timeline is-centered">
                {timelines.map((period, periodIndex) => (
                  <Fragment key={period}>
                    <header className="timeline-header">
                      <span className="tag is-medium is-primary">
                        {period.year}
                      </span>
                    </header>
                    {period.milestones.map((milestone, milestoneIndex) => (
                      <div key={milestone} className="timeline-item ">
                        {getMarkerClass(milestone.marker)}
                        <div className="timeline-content">
                          <p className="heading">{milestone.name}</p>
                          <p>{milestone.text ? milestone.text : "No text"}</p>

                          {milestone.moreText ? (
                            <Fragment>
                              <p
                                id={`moreText${periodIndex}-${milestoneIndex}`}
                                style={{ display: "none" }}
                              >
                                {milestone.moreText}
                              </p>
                              <a
                                id={`seeMore${periodIndex}-${milestoneIndex}`}
                                onClick={toggleSeeMore(
                                  periodIndex,
                                  milestoneIndex,
                                )}
                              >
                                See More
                              </a>
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ))}
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">End</span>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TimelinePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  timelines: PropTypes.object.isRequired,
};

const TimelinePage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <TimelinePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        timelines={post.frontmatter.timelines}
      />
    </Layout>
  );
};

TimelinePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TimelinePage;

export const timelinePageQuery = graphql`
  query TimelinePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        timelines {
          year
          milestones {
            name
            marker
            text
            moreText
          }
        }
      }
    }
  }
`;
